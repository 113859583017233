import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createBlock(_component_q_select, {
    modelValue: _ctx.age,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.age = $event)),
    multiple: "",
    outlined: "",
    "hide-dropdown-icon": "",
    options: _ctx.ages,
    dense: "",
    label: "Ikä",
    "options-dense": "",
    "option-value": "id",
    "option-label": "name",
    "display-value": _ctx.multipleSelectionLabel(_ctx.age)
  }, null, 8, ["modelValue", "options", "display-value"]))
}