import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-2 q-mr-sm" }
const _hoisted_3 = { class: "col-3 q-mx-sm" }
const _hoisted_4 = { class: "col-2 q-mx-sm" }
const _hoisted_5 = { class: "col-1 q-mx-sm" }
const _hoisted_6 = { class: "col-3 q-mx-sm" }
const _hoisted_7 = { class: "row q-mt-sm" }
const _hoisted_8 = { class: "col-2 q-mr-sm" }
const _hoisted_9 = { class: "col-auto q-mr-lg" }
const _hoisted_10 = { class: "col-auto q-mr-lg" }
const _hoisted_11 = { class: "col-auto q-mr-lg" }
const _hoisted_12 = { class: "col-2" }
const _hoisted_13 = { class: "col-auto q-ml-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PalveluyksikkoNameInput = _resolveComponent("PalveluyksikkoNameInput")!
  const _component_LocationInput = _resolveComponent("LocationInput")!
  const _component_MunicipalityInput = _resolveComponent("MunicipalityInput")!
  const _component_AgeInput = _resolveComponent("AgeInput")!
  const _component_PalvelumuotoInput = _resolveComponent("PalvelumuotoInput")!
  const _component_ExpertiseInput = _resolveComponent("ExpertiseInput")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_LanguageInput = _resolveComponent("LanguageInput")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_PalveluyksikkoNameInput, {
          modelValue: _ctx.form.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.form.name = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_LocationInput, {
          modelValue: _ctx.form.location,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.form.location = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_MunicipalityInput, {
          modelValue: _ctx.form.municipality,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.form.municipality = $event)),
          multiple: "",
          location: _ctx.form.location
        }, null, 8, ["modelValue", "location"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_AgeInput, {
          modelValue: _ctx.form.age,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.form.age = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_PalvelumuotoInput, {
          multiple: "",
          modelValue: _ctx.form.palvelumuoto,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.form.palvelumuoto = $event))
        }, null, 8, ["modelValue"])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_ExpertiseInput, {
          modelValue: _ctx.form.expertise,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.form.expertise = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_q_checkbox, {
          modelValue: _ctx.form.availableRoom,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_ctx.form.availableRoom = $event)),
          label: "Vapaita paikkoja"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_q_checkbox, {
          modelValue: _ctx.form.puitesopimus,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_ctx.form.puitesopimus = $event)),
          label: "Mukana puitesopimuksessa"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_q_checkbox, {
          modelValue: _ctx.form.serviceLanguageSwedish,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (_ctx.form.serviceLanguageSwedish = $event)),
          label: "Palvelukielenä ruotsi"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_LanguageInput, {
          modelValue: _ctx.form.language,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (_ctx.form.language = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_q_btn, {
          color: "primary",
          "no-caps": "",
          label: "Hae",
          onClick: _ctx.search
        }, null, 8, ["onClick"])
      ])
    ])
  ]))
}