export const VALVONTA_TYPES = [
    { id: "suunnitelmallinen", name: "Suunnitelmallinen valvonta" },
    { id: "reaktiivinen", name: "Reaktiivinen valvonta" },
    { id: "eoa", name: "EOA" },
    { id: "ennakollinen", name: "Ennakollinen valvonta" },
    { id: "muu", name: "Muu" },
];

export const SEURATTAVAA_TYPES = [
    { id: "laakehoito", name: "Lääkehoito" },
    { id: "henkilosto", name: "Henkilöstö" },
    { id: "dokumentointi", name: "Dokumentointi" },
    { id: "tilat", name: "Tilat" },
    { id: "lasten-hoito", name: "Lasten hoito ja kasvatus" },
    { id: "muu", name: "Muu" },
];
