export const ageRange = (ages: number[]) => {
    if (!ages || !ages.length) {
        return null;
    }
    const sorted = new Float64Array(ages).sort();
    const ageArrays: number[][] = [];
    let arrIterator = 0;
    sorted.forEach((num, index) => {
        if (index && sorted[index - 1] !== num - 1) {
            arrIterator += 1;
        }
        if (!ageArrays[arrIterator]) {
            ageArrays.push([num]);
        } else {
            ageArrays[arrIterator] = ageArrays[arrIterator].concat([num]);
        }
    });
    return ageArrays
        .map((range) =>
            range.length === 1
                ? formatAge(range[0])
                : `${range[0]}-${formatAge(range[range.length - 1])}`
        )
        .join(", ");
};

const formatAge = (age: number) => (age === 18 ? "18+" : age);
