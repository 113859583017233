import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createBlock(_component_q_select, {
    modelValue: _ctx.language,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.language = $event)),
    multiple: "",
    outlined: "",
    dense: "",
    "dropdown-icon": "fas fa-chevron-down",
    options: _ctx.languages,
    label: "Kielitaito",
    "option-value": "id",
    "option-label": "name",
    "options-dense": "",
    "display-value": _ctx.multipleSelectionLabel(_ctx.language)
  }, null, 8, ["modelValue", "options", "display-value"]))
}