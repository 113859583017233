import { Option } from "@/@types/Option";
import { fixPrice } from "@/utils/price";

export const formData = (form: any) => {
    // for erityisosaaminen and speciality, send text field value only if the associated checkbox has been toggled
    const addIfCheckboxToggled = (
        values: { [key: string]: string | null },
        toggled: { [key: string]: boolean }
    ) => {
        return Object.fromEntries(
            Object.entries(toggled).map(([k, v], i) => [k, toggled[k] ? values[k] : null])
        );
    };

    return {
        id: form.id,
        name: form.name,
        contact_person: {
            name: form.contact_person,
            phone_number: form.contact_number,
            email: form.contact_email,
        },
        street_address: form.street_address,
        postal_code: form.postal_code,
        municipality: form.municipality.id,
        website: form.website,
        palvelukieli: form.palvelukieli,
        languages: form.languages ? form.languages.map((l: Option) => l.id) : null,
        prices: form.prices
            ? form.prices.map((p: any) => {
                  return {
                      price_min: fixPrice(p.price_min),
                      price_max: fixPrice(p.price_max),
                      palvelumuoto: p.palvelumuoto.id,
                  };
              })
            : null,
        vacant: Number(form.vacant),
        room: Number(form.room),
        about_to_be_vacant: Number(form.about_to_be_vacant),
        ages: form.ages
            ? form.ages.map((l: Option | number) =>
                  typeof l === "number" || l instanceof Number ? l : l.id
              )
            : null,
        description: form.description,
        erityisosaamiset: addIfCheckboxToggled(form.erityisosaamiset, form.showErityisosaamiset),
        specialities: addIfCheckboxToggled(form.specialities, form.showSpecialities),
    };
};
