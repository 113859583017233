
import { defineComponent, PropType, computed, ref } from "vue";
import { PalveluyksikkoForm } from "../@types/PalveluyksikkoForm";
import MunicipalityInput from "./input/MunicipalityInput.vue";
import LanguageInput from "./input/LanguageInput.vue";
import PalvelumuotoInput from "@/components/input/PalvelumuotoInput.vue";
import AgeInput from "./input/AgeInput.vue";
import { ERITYISOSAAMISET, SPECIALITIES } from "@/modules/palveluhaku/constants/Palveluhaku";
import {
    emailRegex,
    phoneRegex,
    websiteRegex,
    postcodeRegex,
    posIntRegex,
    priceRegex,
} from "@/utils/regex";
import { priceListHasDuplicates } from "../utils/palvelumuoto";

export default defineComponent({
    components: { MunicipalityInput, LanguageInput, PalvelumuotoInput, AgeInput },
    props: {
        modelValue: Object as PropType<PalveluyksikkoForm | null>,
        showError: Boolean,
    },
    emits: ["update:modelValue", "addPriceRow"],
    setup(props, { emit }) {
        const form: any = computed({
            get: () => props.modelValue,
            set: (value) => emit("update:modelValue", value),
        });

        const phoneIsValid = computed(() => phoneRegex.test(form.value.contact_number));
        const emailIsValid = computed(() => emailRegex.test(form.value.contact_email));
        const websiteIsValid = computed(() => websiteRegex.test(form.value.website));
        const postcodeIsValid = computed(() => postcodeRegex.test(form.value.postal_code));
        const roomIsValid = computed(() => posIntRegex.test(form.value.room));
        const vacantIsValid = computed(() => posIntRegex.test(form.value.vacant));
        const aboutToBeVacantIsValid = computed(() =>
            posIntRegex.test(form.value.about_to_be_vacant)
        );

        const priceIsValid = (price: string | number | null): boolean => {
            if (!price || typeof price == "number" || priceRegex.test(price)) {
                return true;
            }
            return false;
        };

        const palvelukieliOptions = [
            { label: "suomi", id: "suomi" },
            { label: "ruotsi", id: "ruotsi" },
        ];

        const newPrice = () => {
            emit("addPriceRow");
        };

        return {
            form,
            palvelukieliOptions,
            emailIsValid,
            phoneIsValid,
            websiteIsValid,
            postcodeIsValid,
            roomIsValid,
            vacantIsValid,
            aboutToBeVacantIsValid,
            newPrice,
            ERITYISOSAAMISET,
            SPECIALITIES,
            priceListHasDuplicates,
            priceIsValid,
        };
    },
});
