import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createBlock(_component_q_select, {
    onFilter: _ctx.filterFn,
    outlined: "",
    "hide-dropdown-icon": "",
    loading: !_ctx.filteredPalveluyksikot,
    options: _ctx.filteredPalveluyksikot,
    label: _ctx.label || 'Hae nimellä',
    "menu-shrink": "",
    "option-value": "id",
    "option-label": "name",
    "use-input": "",
    "fill-input": "",
    "input-debounce": "0",
    "hide-selected": "",
    dense: "",
    "options-dense": "",
    "model-value": _ctx.palveluyksikko,
    onInputValue: _ctx.setYksikko,
    onClear: _ctx.clearYksikko,
    clearable: "",
    "clear-icon": "fas fa-times"
  }, _createSlots({ _: 2 }, [
    (!_ctx.hideIcon)
      ? {
          name: "prepend",
          fn: _withCtx(() => [
            _createVNode(_component_q_icon, { name: "fas fa-search" })
          ])
        }
      : undefined
  ]), 1032, ["onFilter", "loading", "options", "label", "model-value", "onInputValue", "onClear"]))
}