
import { defineComponent, computed, PropType } from "vue";
import { multipleSelectionLabel } from "@/modules/palveluhaku/utils/input-utils";
import { ERITYISOSAAMISET } from "@/modules/palveluhaku/constants/Palveluhaku";
import { Option } from "@/@types/Option";

export default defineComponent({
    props: {
        modelValue: Object as PropType<Option>,
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        const erityisosaaminen = computed({
            get: () => props.modelValue,
            set: (value) => emit("update:modelValue", value),
        });
        return {
            erityisosaamiset: ERITYISOSAAMISET,
            erityisosaaminen,
            multipleSelectionLabel,
        };
    },
});
