<template>
    <div v-if="loading" class="loading-container row justify-center">
        <q-linear-progress indeterminate class="q-mb-md" />
    </div>
    <router-view v-else />
</template>

<script>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import hasRole from "@/utils/hasRole";
import { ROLE } from "@/constants/roles";

export default {
    setup() {
        const store = useStore();

        const isPalveluntuottaja = computed(() => {
            return hasRole([ROLE.PALVELUNTUOTTAJA]);
        });

        const loading = computed(() => store.state.palveluyksikotLoading);

        // Don't fetch palveluyksikkö list for palveluntuottaja role
        if (!isPalveluntuottaja.value) {
            const fetchPalveluyksikot = () => store.dispatch("fetchPalveluyksikot");
            onMounted(fetchPalveluyksikot);
        }

        return { loading };
    },
};
</script>
