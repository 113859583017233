
import { defineComponent, PropType, computed, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
    props: {
        modelValue: String,
        hideIcon: Boolean,
        label: String,
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        const palveluyksikko = computed({
            get: () => props.modelValue,
            set: (value) => emit("update:modelValue", value),
        });

        const store = useStore();

        const palveluyksikot = computed(() => store.state.palveluyksikot);
        const filteredPalveluyksikot = ref(palveluyksikot.value);

        const setYksikko = (val: string) => {
            palveluyksikko.value = val;
        };

        const clearYksikko = () => {
            palveluyksikko.value = "";
        };

        function filterFn(val: string, update: any) {
            update(() => {
                if (val.length >= 3 || !val.length) {
                    const needle = val.toLowerCase();
                    filteredPalveluyksikot.value = palveluyksikot.value.filter(
                        (item: any) => item.name.toLowerCase().indexOf(needle) > -1
                    );
                }
            });
        }

        return { filteredPalveluyksikot, filterFn, palveluyksikko, setYksikko, clearYksikko };
    },
});
