
import { defineComponent, computed, PropType } from "vue";
import { PalveluhakuResult } from "@/modules/palveluhaku/@types/PalveluhakuResult";
import { formattedDate } from "@/utils/date";
import price from "@/modules/palveluhaku/utils/price";

export default defineComponent({
    emits: ["click"],
    props: {
        result: {
            type: Object as PropType<PalveluhakuResult>,
            required: true,
        },
        showUpdated: Boolean,
    },
    setup(props) {
        const date = computed(() => formattedDate(props.result.updated_at));

        const priceRange = computed(() => price(props.result));

        return { date, priceRange };
    },
});
