
import { computed, defineComponent, reactive } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import type { PalveluhakuForm } from "@/modules/palveluhaku/@types/PalveluhakuForm";
import PalveluyksikkoNameInput from "./input/PalveluyksikkoNameInput.vue";
import LocationInput from "./input/LocationInput.vue";
import MunicipalityInput from "./input/MunicipalityInput.vue";
import AgeInput from "./input/AgeInput.vue";
import PalvelumuotoInput from "@/components/input/PalvelumuotoInput.vue";
import ExpertiseInput from "./input/ExpertiseInput.vue";
import LanguageInput from "./input/LanguageInput.vue";
import { PalveluhakuParams } from "../@types/PalveluhakuParams";
import { mapOptionToId } from "@/utils/option";

const palveluhakuForm: PalveluhakuForm = {
    name: "",
    location: [],
    municipality: [],
    age: [],
    palvelumuoto: [],
    expertise: [],
    language: [],
    puitesopimus: false,
    availableRoom: false,
    serviceLanguageSwedish: false,
};

const initialForm = { ...palveluhakuForm };

export default defineComponent({
    components: {
        PalveluyksikkoNameInput,
        LocationInput,
        MunicipalityInput,
        AgeInput,
        PalvelumuotoInput,
        ExpertiseInput,
        LanguageInput,
    },
    emits: ["search"],
    setup(props, { emit }) {
        const store = useStore();
        const route = useRoute();

        const cameFromValvontatiedot = computed(() => route.query.cameFrom === "valvontatiedot");
        const form: PalveluhakuForm = reactive(palveluhakuForm);

        if (!cameFromValvontatiedot.value) {
            Object.assign(form, initialForm);
        }

        const search = () => {
            const params: PalveluhakuParams = {
                name: form.name,
                location: mapOptionToId(form.location),
                municipality: mapOptionToId(form.municipality),
                age: mapOptionToId(form.age) as any,
                palvelumuoto: mapOptionToId(form.palvelumuoto),
                expertise: mapOptionToId(form.expertise),
                availableRoom: form.availableRoom,
                puitesopimus: form.puitesopimus,
                language: mapOptionToId(form.language),
                service_language: form.serviceLanguageSwedish ? ["ruotsi"] : null,
            };
            store.dispatch("palveluhaku/fetchSearchResults", params);
            emit("search", form);
        };

        return { form, search };
    },
});
