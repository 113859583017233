import { PALVELUMUODOT } from "@/modules/palveluhaku/constants/Palveluhaku";
import type { Price } from "@/modules/palveluhaku/@types/PalveluhakuResult";
import type { PalveluhakuForm } from "@/modules/palveluhaku/@types/PalveluhakuForm";

const selectedPalvelumuoto = (
    palvelumuoto: PalveluhakuForm["palvelumuoto"] | null,
    prices: Price[]
): any[] => {
    return (
        palvelumuoto && palvelumuoto.length
            ? prices.filter((p: any) =>
                  palvelumuoto.some((t: any) => t.id === p.palvelumuoto.toLowerCase())
              )
            : prices
    ).map((p: any) => ({ ...p, name: palvelumuotoName(p.palvelumuoto) }));
};

const palvelumuotoName = (palvelumuoto: string) =>
    PALVELUMUODOT.find((t) => t.id === palvelumuoto.toLowerCase())?.name;

// Used for validating palveluyksikkö form price list
// Each palvelumuoto should only appear once in the list
const priceListHasDuplicates = (priceList: (Price | Record<string, never>)[]) => {
    return (
        [
            ...new Set(
                priceList.map((priceRow: Price | Record<string, never>) => priceRow.palvelumuoto)
            ),
        ].length !== priceList.length
    );
};

export { selectedPalvelumuoto, palvelumuotoName, priceListHasDuplicates };
