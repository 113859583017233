
import { defineComponent, PropType, computed, ref, reactive } from "vue";
import Header from "./Header.vue";
import { PalveluhakuResult } from "../@types/PalveluhakuResult";
import useAuthorisation from "@/utils/useAuthorization";
import { ROLE } from "@/constants/roles";
import { formData } from "@/modules/palveluhaku/utils/form";
import Dialog from "../../../components/Dialog.vue";
import Palveluyksikkoform from "./Palveluyksikkoform.vue";
import { PalveluyksikkoForm } from "../@types/PalveluyksikkoForm";
import { useStore } from "vuex";
import {
    emailRegex,
    phoneRegex,
    websiteRegex,
    postcodeRegex,
    posIntRegex,
} from "@/utils/regex";
import {
    ERITYISOSAAMISET,
    PALVELUMUODOT,
    SPECIALITIES,
} from "@/modules/palveluhaku/constants/Palveluhaku";
import { Option } from "@/@types/Option";
import { LANGUAGES } from "@/modules/palveluhaku/constants/Palveluhaku";
import { openErrorNotification } from "@/components/Notification";
import { validatePrices } from "@/utils/price";
import { priceListHasDuplicates } from "../utils/palvelumuoto";

export default defineComponent({
    components: { Header, Dialog, Palveluyksikkoform },
    emits: ["click"],
    props: {
        result: {
            type: Object as PropType<PalveluhakuResult>,
            required: true,
        },
        selected: Boolean,
        palvelumuotoName: Function,
        palvelumuoto: Array,
    },
    setup() {
        const isPalveluntuottaja = computed(() => {
            const { canAccess } = useAuthorisation([ROLE.PALVELUNTUOTTAJA]);
            return canAccess;
        });

        // use empty form as initialState so we can use the same form to edit any palveluyksikko
        const initialState: PalveluyksikkoForm = {
            id: null,
            name: "",
            contact_number: "",
            street_address: "",
            postal_code: "",
            municipality: { id: "", name: "" },
            contact_person: "",
            contact_email: "",
            website: "",
            palvelukieli: [],
            languages: [],
            prices: [],
            vacant: 0,
            room: 0,
            about_to_be_vacant: 0,
            ages: [],
            description: "",
            erityisosaamiset: {},
            showErityisosaamiset: {},
            specialities: {},
            showSpecialities: {},
        };
        const form = reactive({ ...initialState });
        function resetForm() {
            Object.assign(form, initialState);
            hideDialog();
        }

        const modalOpen = ref(false);
        const showDialog = (result: PalveluhakuResult) => {
            modalOpen.value = true;

            // erityisosaamiset and specialities are read from object keys
            // this function ensures the object always has all the necessary keys
            const ensureObjHasAllKeys = (
                obj: {
                    [key: string]: string | null;
                },
                keyArray: Option[]
            ) => {
                const objFull = {};
                if (obj) {
                    Object.assign(objFull, obj);
                }
                keyArray.forEach((e) => {
                    if (objFull && e.id in objFull) return;
                    Object.assign(objFull, { [e.id]: null });
                });
                return objFull;
            };

            const mapObjValueToBool = (
                obj: { [key: string]: string | null },
                keyArray: Option[]
            ) => {
                return Object.fromEntries(
                    Object.entries(ensureObjHasAllKeys(obj, keyArray)).map(([k, v], i) => [
                        k,
                        Boolean(v),
                    ])
                );
            };

            Object.assign(form, {
                id: result.id,
                name: result.name,
                contact_number: result.contact_number,
                street_address: result.street_address,
                postal_code: result.postal_code,
                municipality: { id: result.municipality, name: result.municipality },
                contact_person: result.contact_person,
                contact_email: result.contact_email,
                website: result.website,
                palvelukieli: result.palvelukieli || [],
                languages: result.languages
                    ? LANGUAGES.filter((l: Option) =>
                          result.languages.some((rl: any) => l.id === rl)
                      )
                    : [],
                prices: result.prices.map((p) => {
                    return {
                        price_min: p.price_min,
                        price_max: p.price_max,
                        palvelumuoto: PALVELUMUODOT.find((pm: Option) => pm.id === p.palvelumuoto),
                    };
                }),
                vacant: result.vacant,
                room: result.room,
                about_to_be_vacant: result.about_to_be_vacant,
                ages: result.ages,
                description: result.description,
                erityisosaamiset: ensureObjHasAllKeys(result.erityisosaamiset, ERITYISOSAAMISET),
                showErityisosaamiset: mapObjValueToBool(result.erityisosaamiset, ERITYISOSAAMISET),
                specialities: ensureObjHasAllKeys(result.specialities, SPECIALITIES),
                showSpecialities: mapObjValueToBool(result.specialities, SPECIALITIES),
            });
        };
        const hideDialog = () => {
            Object.assign(form, initialState);
            modalOpen.value = false;
        };

        const showError = ref(false);

        const checkedFieldsAreFilled = (
            checkboxObject: { [key: string]: boolean },
            fieldObject: { [key: string]: string | null }
        ): boolean => {
            return !Object.keys(checkboxObject).some((k) => {
                return checkboxObject[k] && !fieldObject[k];
            });
        };

        function checkError() {
            const validateFields = (form: any) => {
                return (
                    form.id &&
                    form.name &&
                    form.contact_number &&
                    form.street_address &&
                    form.postal_code &&
                    form.municipality &&
                    phoneRegex.test(form.contact_number) &&
                    (!form.contact_email || emailRegex.test(form.contact_email)) &&
                    (!form.website || websiteRegex.test(form.website)) &&
                    postcodeRegex.test(form.postal_code) &&
                    (!form.room || posIntRegex.test(form.room)) &&
                    (!form.vacant || posIntRegex.test(form.vacant)) &&
                    (!form.about_to_be_vacant || posIntRegex.test(form.about_to_be_vacant)) &&
                    checkedFieldsAreFilled(form.showErityisosaamiset, form.erityisosaamiset) &&
                    checkedFieldsAreFilled(form.showSpecialities, form.specialities) &&
                    validatePrices(form.prices) &&
                    !priceListHasDuplicates(form.prices)
                );
            };

            const requiredFieldsFilled = validateFields(form);
            if (requiredFieldsFilled) {
                saveForm();
                showError.value = false;
            } else {
                showError.value = true;
                openErrorNotification("Tallentaminen epäonnistui. Tarkasta merkityt kentät.");
            }
        }

        const store = useStore();

        function saveForm() {
            const palveluyksikkoNewData = formData(form);
            store.dispatch("palveluhaku/savePalveluyksikko", JSON.stringify(palveluyksikkoNewData));

            resetForm();
            hideDialog();
        }

        const addPriceRow = () => {
            const oneMorePrice = form.prices.concat({});
            Object.assign(form, { ...form, prices: oneMorePrice });
        };

        return {
            isPalveluntuottaja,
            modalOpen,
            showDialog,
            hideDialog,
            resetForm,
            checkError,
            showError,
            form,
            addPriceRow,
        };
    },
});
