
import { defineComponent, reactive, toRefs, computed, ref, Ref, ComputedRef, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Haku from "../components/Haku.vue";
import Hakutulos from "../components/Hakutulos.vue";
import Palveluyksikko from "../components/Palveluyksikko.vue";
import { PalveluhakuForm } from "../@types/PalveluhakuForm";
import { PalveluhakuResult, Price } from "../@types/PalveluhakuResult";
import { selectedPalvelumuoto } from "@/modules/palveluhaku/utils/palvelumuoto";
import { palvelumuotoName } from "@/modules/palveluhaku/utils/palvelumuoto";
import { ROLE } from "@/constants/roles";
import useAuthorisation from "@/utils/useAuthorization";
import { PalveluhakuParams } from "../@types/PalveluhakuParams";
import { OMISTUSMUODOT } from "@/constants/omistusmuoto";

interface PriceRange {
    price_min: number;
    price_max: number;
}

export default defineComponent({
    components: { Haku, Hakutulos, Palveluyksikko },
    setup() {
        const { canAccess: canAccessPalveluhakuSearchFields } = useAuthorisation([
            ROLE.TYONTEKIJA,
            ROLE.KUNTAMANAGER,
            ROLE.SIHTEERI,
            ROLE.VALVOJA,
            ROLE.VALVOJAMANAGER,
        ]);

        const status = reactive({
            clean: true,
            results: [],
        });

        const store = useStore();

        const route = useRoute();

        const cameFromValvontatiedot = computed(() => route.query.cameFrom === "valvontatiedot");

        // For palveluntarjoaja, fetch all palveluyksikkö
        // Backend will only return palveluyksikkö where palveluntarjoaja has view permissions
        if (!canAccessPalveluhakuSearchFields) {
            const searchParams: PalveluhakuParams = {
                name: "",
                location: [],
                municipality: [],
                age: [],
                palvelumuoto: [],
                expertise: [],
                availableRoom: false,
                puitesopimus: false,
                language: [],
                service_language: null,
            };
            store.dispatch("palveluhaku/fetchSearchResults", searchParams);
        }

        const selectedResultId: Ref<number | null> = ref(null);
        const palvelumuoto: Ref<PalveluhakuForm["palvelumuoto"] | null> = ref(null);
        const puitesopimus: Ref<PalveluhakuForm["puitesopimus"] | null> = ref(null);

        const hakuResults = computed(() => store.state.palveluhaku.searchResults);

        const showResults: Ref<boolean> = ref(false);

        watch(hakuResults, () => {
            showResults.value = true;
        });

        const searchResults: ComputedRef<PalveluhakuResult[]> = computed(() => {
            if (!showResults.value && !cameFromValvontatiedot.value) {
                return [];
            }

            const resultsWithRange = hakuResults.value.map((result: PalveluhakuResult) => {
                const { puitesopimus_prices, prices } = result;
                let range;
                let hasPuitesopimus = false;
                if (puitesopimus.value || puitesopimus_prices.length) {
                    range = priceRange(puitesopimus_prices);
                    // range palauttaa tyhjän objektin jos prices-lista ei sisällä haun palvelumuotoja
                    hasPuitesopimus = !!Object.keys(range).length;
                }
                if (!range || !hasPuitesopimus) {
                    range = priceRange(prices);
                }
                return {
                    ...result,
                    hasPuitesopimus,
                    price_min: range.price_min,
                    price_max: range.price_max,
                };
            });
            return resultsWithRange
                .sort((r1: any, r2: any) => {
                    if (r1.hasPuitesopimus && !r2.hasPuitesopimus) {
                        return -1;
                    }
                    if (!r1.hasPuitesopimus && r2.hasPuitesopimus) {
                        return 1;
                    }
                    const r1Value = r1.price_min || r1.price_max;
                    const r2Value = r2.price_min || r2.price_max;
                    if (r1Value && r2Value) {
                        return r1Value < r2Value ? -1 : 1;
                    } else if (!r1Value) {
                        return 1;
                    } else if ((!r1Value && r2Value) || (!r2Value && r1Value)) {
                        return -1;
                    }
                })
                .map((r: any) => ({
                    ...r,
                    omistusmuoto: OMISTUSMUODOT.find((om) => om.id === r.omistusmuoto)?.name,
                }));
        });
        const isLoadingResults = computed(() => store.state.palveluhaku.isLoadingResults);

        const onSearch = (form: PalveluhakuForm) => {
            palvelumuoto.value = form.palvelumuoto;
            puitesopimus.value = form.puitesopimus;
            status.clean = false;
        };

        const mapPalvelumuotoNames = (prices?: Price[]) =>
            prices?.map((p: any) => ({
                ...p,
                name: palvelumuotoName(p.palvelumuoto),
            }));

        const selectedResultWithPalvelumuoto = computed(() => ({
            ...selectedResult.value,
            prices: mapPalvelumuotoNames(selectedResult.value?.prices),
            puitesopimus_prices: mapPalvelumuotoNames(selectedResult.value?.puitesopimus_prices),
        }));

        const onResultClick = (resultId: number) => {
            selectedResultId.value = resultId;
        };

        const selectedResult = computed(() =>
            searchResults.value.find((r: PalveluhakuResult) => r.id === selectedResultId.value)
        );

        const resultPalvelumuoto = (result: PalveluhakuResult) =>
            selectedPalvelumuoto(
                palvelumuoto.value,
                result.hasPuitesopimus ? result.puitesopimus_prices : result.prices
            );

        const priceRange = (prices: Price[]): PriceRange => {
            const filteredPrices = selectedPalvelumuoto(palvelumuoto.value, prices);
            const range: PriceRange = filteredPrices.length
                ? filteredPrices.reduce((rangeObj: PriceRange, item): any => ({
                      price_min:
                          item.price_min && rangeObj.price_min > item.price_min
                              ? item.price_min
                              : rangeObj.price_min,
                      price_max:
                          item.price_max && rangeObj.price_max < item.price_max
                              ? item.price_max
                              : rangeObj.price_max,
                  }))
                : {};
            return range;
        };

        const { clean, results } = toRefs(status);

        return {
            isLoadingResults,
            clean,
            results,
            selectedResult,
            onSearch,
            searchResults,
            onResultClick,
            priceRange,
            resultPalvelumuoto,
            selectedResultWithPalvelumuoto,
            canAccessPalveluhakuSearchFields,
        };
    },
});
