
import { defineComponent, computed, PropType } from "vue";
import { multipleSelectionLabel } from "@/modules/palveluhaku/utils/input-utils";
import { Option } from "@/@types/Option";

export const LOCATIONS = [
    { id: "etelakarjala", name: "Etelä-Karjalan hyvinvointialue" },
    { id: "etelapohjanmaa", name: "Etelä-Pohjanmaan hyvinvointialue" },
    { id: "etelasavo", name: "Etelä-Savon hyvinvointialue" },
    { id: "helsinki", name: "Helsingin kaupunki" },
    { id: "itauusimaa", name: "Itä-Uudenmaan hyvinvointialue" },
    { id: "kainuu", name: "Kainuun hyvinvointialue" },
    { id: "kantahame", name: "Kanta-Hämeen hyvinvointialue" },
    { id: "keskisuomi", name: "Keski-Suomen hyvinvointialue" },
    { id: "keskipohjanmaa", name: "Keski-Pohjanmaan hyvinvointialue" },
    { id: "keskiuusimaa", name: "Keski-Uudenmaan hyvinvointialue" },
    { id: "kymenlaakso", name: "Kymenlaakson hyvinvointialue" },
    { id: "lappi", name: "Lapin hyvinvointialue" },
    { id: "lansiuusimaa", name: "Länsi-Uudenmaan hyvinvointialue" },
    { id: "satakunta", name: "Satakunnan hyvinvointialue" },
    { id: "pirkanmaa", name: "Pirkanmaan hyvinvointialue" },
    { id: "pohjanmaa", name: "Pohjanmaan hyvinvointialue" },
    { id: "pohjoiskarjala", name: "Pohjois-Karjalan hyvinvointialue" },
    { id: "pohjoispohjanmaa", name: "Pohjois-Pohjanmaan hyvinvointialue" },
    { id: "pohjoissavo", name: "Pohjois-Savon hyvinvointialue" },
    { id: "paijathame", name: "Päijät-Hämeen hyvinvointialue" },
    { id: "vantaakerava", name: "Vantaa-Keravan hyvinvointialue" },
    { id: "varsinaissuomi", name: "Varsinais-Suomen hyvinvointialue" },
    { id: "ahvenanmaa", name: "Ahvenanmaa" },
];

export default defineComponent({
    props: {
        modelValue: Object as PropType<Option>,
        hideIcon: Boolean,
        single: Boolean,
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        const location = computed({
            get: () => props.modelValue,
            set: (value) => emit("update:modelValue", value),
        });
        return { locations: LOCATIONS, location, multipleSelectionLabel };
    },
});
