const price = (result: any, showLabel?: boolean) => {
    const { price_min, price_max } = result;
    if (price_min && price_max) {
        return `${price_min} - ${price_max} €${showLabel ? "/vrk" : ""}`;
    } else if (price_min || price_max) {
        return `${price_min || ""}${price_max || ""} €${showLabel ? "/vrk" : ""}`;
    }
    return null;
};

export default price;
