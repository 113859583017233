
import { defineComponent, PropType, computed } from "vue";
import Header from "./Header.vue";
import { PalveluhakuResult } from "../@types/PalveluhakuResult";
import { ERITYISOSAAMISET, SPECIALITIES } from "@/modules/palveluhaku/constants/Palveluhaku";
import price from "@/modules/palveluhaku/utils/price";
import { formattedDate } from "@/utils/date";
import { VALVONTA_TYPES } from "@/modules/valvontatiedot/constants/Valvontatiedot";
import { ageRange } from "@/modules/palveluhaku/utils/age";

export default defineComponent({
    components: { Header },
    props: {
        result: {
            type: Object as PropType<PalveluhakuResult>,
            required: true,
        },
        palvelumuoto: Array,
    },
    setup(props) {
        const expertiseTitle = (value: string): string | undefined => {
            return ERITYISOSAAMISET.find((t) => t.id === value)?.name;
        };

        const valvontaControlDate = computed(() =>
            props.result.valvontatiedot?.control_date
                ? formattedDate(props.result.valvontatiedot.control_date)
                : undefined
        );
        const valvontaType = (value: string): string | undefined => {
            return VALVONTA_TYPES.find((t) => t.id === value)?.name;
        };
        const palvelukieliList = computed(() => props.result.palvelukieli);
        const specialities = computed(() => {
            const { specialities } = props.result;
            return SPECIALITIES.filter((s) => specialities && specialities[s.id]).map((s) => ({
                ...s,
                value: specialities[s.id],
            }));
        });

        const ageRangeStr = computed(() => ageRange(props.result.ages));

        return {
            expertiseTitle,
            price,
            palvelukieliList,
            specialities,
            ageRangeStr,
            valvontaControlDate,
            valvontaType,
        };
    },
});
