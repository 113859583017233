import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createBlock(_component_q_select, {
    modelValue: _ctx.location,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.location = $event)),
    multiple: _ctx.single ? false : true,
    outlined: "",
    "hide-dropdown-icon": "",
    "dropdown-icon": "fas fa-chevron-down",
    options: _ctx.locations,
    label: "Hyvinvointialue",
    "menu-shrink": "",
    "option-value": "id",
    "option-label": "name",
    dense: "",
    "options-dense": "",
    "display-value": _ctx.single ? _ctx.location?.name : _ctx.multipleSelectionLabel(_ctx.location)
  }, _createSlots({ _: 2 }, [
    (!_ctx.hideIcon)
      ? {
          name: "prepend",
          fn: _withCtx(() => [
            _createVNode(_component_q_icon, { name: "fas fa-map-marker-alt" })
          ])
        }
      : undefined
  ]), 1032, ["modelValue", "multiple", "options", "display-value"]))
}