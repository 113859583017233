
import { defineComponent, computed, PropType } from "vue";
import { multipleSelectionLabel } from "@/modules/palveluhaku/utils/input-utils";
import { Option } from "@/@types/Option";

export default defineComponent({
    props: {
        modelValue: Object as PropType<Option>,
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        const age = computed({
            get: () => props.modelValue,
            set: (value) => emit("update:modelValue", value),
        });
        const ages = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].map(
            (age) => ({ id: age, name: age === 18 ? "18+" : age })
        );
        return { ages, age, multipleSelectionLabel };
    },
});
