
import { defineComponent } from "vue";

export default defineComponent({
    emits: ["click"],
    props: {
        title: {
            type: String,
            required: true,
        },
        modalOpen: {
            type: Boolean,
            required: true,
        },
        saveDisabled: {
            type: Boolean,
            required: false,
        },
        onSave: {
            type: Function,
            required: true,
        },
        onCancel: {
            type: Function,
            required: true,
        },
        hideDialog: {
            type: Function,
            required: true,
        },
        customClass: {
            type: String,
            required: false,
        },
        loading: {
            type: Boolean,
            required: false,
        },
        actionButton: {
            type: String,
            required: false,
        },
        actionButtonIcon: {
            type: String,
            required: false,
        },
    },
});
