import { Option } from "@/@types/Option";

// TODO: These could possibly be done with Quasar emit-value and map-options attributes

export function mapOptionToId(options: Option[]): string[] {
    return options ? options.map((e) => e.id) : [];
}

export function mapOptionToName(option: Option | null): string {
    return option ? option.name : "";
}
