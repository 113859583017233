import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-36a261a5")
const _hoisted_1 = { class: "row justify-between q-pb-sm q-mb-md header" }
const _hoisted_2 = { class: "row justify-between" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    "model-value": _ctx.modalOpen,
    class: _normalizeClass(_ctx.customClass),
    onHide: _ctx.hideDialog
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, {
        class: "dialog q-pa-md",
        style: { maxWidth: '700px', width: '90%' }
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("h3", null, _toDisplayString(_ctx.title), 1),
            _createVNode(_component_q_icon, {
              name: "fas fa-times clickable",
              color: "light-text",
              onClick: _ctx.onCancel
            }, null, 8, ["onClick"])
          ]),
          _renderSlot(_ctx.$slots, "default", {}, undefined, true),
          _createElementVNode("div", _hoisted_2, [
            _withDirectives(_createVNode(_component_q_btn, {
              flat: "",
              label: "Peruuta",
              color: "light-text",
              "no-caps": "",
              onClick: _ctx.onCancel
            }, null, 8, ["onClick"]), [
              [_directive_close_popup]
            ]),
            _createVNode(_component_q_btn, {
              label: _ctx.actionButton || 'Tallenna',
              color: "primary",
              "no-caps": "",
              icon: _ctx.actionButtonIcon || undefined,
              onClick: _ctx.onSave,
              loading: _ctx.loading,
              disabled: _ctx.saveDisabled
            }, null, 8, ["label", "icon", "onClick", "loading", "disabled"])
          ])
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["model-value", "class", "onHide"]))
}