import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-9a66a7e2")
const _hoisted_1 = { class: "row justify-between q-mt-md" }
const _hoisted_2 = { class: "col-4" }
const _hoisted_3 = { class: "smaller-body dense" }
const _hoisted_4 = { class: "text-icon q-mt-xs" }
const _hoisted_5 = { class: "smaller-body" }
const _hoisted_6 = { class: "col-4" }
const _hoisted_7 = {
  key: 0,
  class: "text-icon"
}
const _hoisted_8 = { class: "smaller-body" }
const _hoisted_9 = {
  key: 1,
  class: "text-icon"
}
const _hoisted_10 = { class: "smaller-body" }
const _hoisted_11 = {
  key: 2,
  class: "text-icon"
}
const _hoisted_12 = ["href"]
const _hoisted_13 = { class: "col-4 self-end right-aligned" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Palveluyksikkoform = _resolveComponent("Palveluyksikkoform")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_Header = _resolveComponent("Header")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Dialog, {
      "modal-open": _ctx.modalOpen,
      title: "Muokkaa toimintayksikön tietoja",
      "on-cancel": _ctx.resetForm,
      "on-save": _ctx.checkError,
      "hide-dialog": _ctx.hideDialog,
      "save-disabled": false,
      "custom-class": "palveluyksikko-dialog"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Palveluyksikkoform, _mergeProps(_ctx.$attrs, {
          modelValue: _ctx.form,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.form = $event)),
          "show-error": _ctx.showError,
          onAddPriceRow: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addPriceRow()))
        }), null, 16, ["modelValue", "show-error"])
      ]),
      _: 1
    }, 8, ["modal-open", "on-cancel", "on-save", "hide-dialog"]),
    _createVNode(_component_q_card, {
      flat: "",
      class: _normalizeClass(`${_ctx.selected ? 'selected' : ''} q-px-md q-pt-sm q-pb-md q-mb-sm clickable`),
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('click', _ctx.result.name)))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Header, _normalizeProps(_guardReactiveProps({ result: _ctx.result })), null, 16),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.palvelumuoto, (item) => {
              return (_openBlock(), _createElementBlock("div", { key: item }, [
                _createElementVNode("div", _hoisted_3, _toDisplayString(item.name), 1)
              ]))
            }), 128)),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_q_icon, { name: "fas fa-map-marker-alt" }),
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.result.municipality), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            (_ctx.result.contact_person)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_component_q_icon, { name: "fas fa-user" }),
                  _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.result.contact_person), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.result.contact_number)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createVNode(_component_q_icon, { name: "fas fa-phone-alt" }),
                  _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.result.contact_number), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.result.website)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createVNode(_component_q_icon, { name: "fas fa-globe" }),
                  _createElementVNode("a", {
                    href: _ctx.result.website,
                    target: "_blank",
                    class: "smaller-body link-text"
                  }, " Kotisivut ", 8, _hoisted_12)
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_13, [
            (!_ctx.isPalveluntuottaja)
              ? (_openBlock(), _createBlock(_component_q_btn, {
                  key: 0,
                  color: "secondary",
                  "no-caps": "",
                  label: "Valvontatiedot",
                  class: "link",
                  to: `/valvontatiedot/${_ctx.result.id}?cameFrom=palveluhaku`
                }, null, 8, ["to"]))
              : _createCommentVNode("", true),
            (_ctx.isPalveluntuottaja)
              ? (_openBlock(), _createBlock(_component_q_btn, {
                  key: 1,
                  color: "secondary",
                  "no-caps": "",
                  label: "Muokkaa tietoja",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showDialog(_ctx.result)))
                }))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _: 1
    }, 8, ["class"])
  ], 64))
}